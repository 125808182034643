import { ChakraProvider, Button, VStack, Text } from '@chakra-ui/react';
import './App.css'; // Importieren Sie Ihre CSS-Datei für benutzerdefinierte Styles

function App() {
  return (
    <ChakraProvider>
      <div className="app-container">
        <VStack  align="center" mt={10}>
          <Text
          
          >
            Life outside digital heaven
          </Text>
          <Button
            as="a"
            href="https://city.recolorred.online"
            height="3rem"
            w="12rem"
            backgroundColor="black"
            color="White"
            borderRadius="0px"
            fontSize="1.5rem"
            mt="2rem"
            _hover="none"
            fontWeight="normal"
          >
            ENTER CITY
          </Button>
        </VStack>
      </div>
    </ChakraProvider>
  );
}

export default App;
